<template>
  <v-row>
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="primary d-none d-md-flex align-center justify-center backgroundImg"
    >
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div class="d-flex justify-center">
                <img src="@/assets/images/logos/logo-vumetric-telus-white.svg" alt="Vumetric logo">
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  class="mt-4 text-capitalize"
                  x-large
                  outlined
                  color="white"
                  @click="goToCorporateWebsite"
                >
                  Learn More
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <h2 class="font-weight-bold mt-4">
                Register
              </h2>
              <v-row justify="center" align="center">
                <v-col v-if="azure" cols="12">
                  <v-btn
                    large
                    block
                    outlined
                    text
                    class="text-capitalize"
                    @click="ssoConnection('azure')"
                  >
                    <img
                      class="mr-2"
                      src="@/assets/images/logos/microsoft_logo.svg"
                      alt="Microsoft logo"
                    >
                    Microsoft
                  </v-btn>
                </v-col>
                <v-col v-if="google" cols="12">
                  <v-btn
                    large
                    block
                    outlined
                    text
                    class="text-capitalize"
                    @click="ssoConnection('google')"
                  >
                    <img
                      class="mr-2"
                      src="@/assets/images/logos/google_logo.svg"
                      alt="Google logo"
                    >
                    Google
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="provider === null"
                class="text-center font-weight-thin my-5 text--grey"
              >
                <v-col cols="3" class="pt-0 ml-3">
                  <v-divider class="ml-4 mt-3" />
                </v-col>
                <v-col cols="5" class="px-0 pt-0">
                  <div class="pa-0">Or register account with</div>
                </v-col>
                <v-col cols="3" class="pt-0">
                  <v-divider class="mr-4  mt-3" />
                </v-col>
              </v-row>
              <v-form
                v-if="internal"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="phone"
                  label="Phone"
                  :rules="phoneRules"
                  class="mt-4"
                  type="tel"
                  required
                  outlined
                />
                <template v-if="result">
                  <v-card class="mb-4 pa-4" color="grey" elevation="0">
                    <div>The password score is {{ result.score }}/4</div>
                    <v-progress-linear
                      v-if="password"
                      :v-model="result.score * 25"
                      rounded
                      class="mb-4 mt-2"
                    />
                    <div class="mx-2 mt-2">
                      <div class="mb-2" v-if="result.feedback.warning"><b class="warning--text">Warning!</b>
                        <div>- {{ result.feedback.warning }}</div>
                      </div>
                      <div v-if="result.feedback.suggestions.length >= 1"><b class="primary--text text--lighten-2">Suggestions:</b>
                        <span
                          v-for="(suggestion, index) in result.feedback.suggestions"
                          :key="index"
                        >
                          <div> - {{ suggestion }}</div>
                        </span>
                      </div>
                    </div>
                  </v-card>
                </template>
                <v-text-field
                  v-model="password"
                  label="Password"
                  :type="valuePW ? 'password' : 'text'"
                  :rules="passwordRules"
                  required
                  outlined
                >
                  <v-icon
                    slot="append"
                    color="primary"
                    @click="valuePW = !valuePW"
                  >
                    {{ valuePW ? 'mdi-eye' : 'mdi-eye-off' }}
                  </v-icon>
                </v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  label="Confirm Password"
                  :type="valueCPW ? 'password' : 'text'"
                  :rules="[v => !!v || 'Confirm Password is required', (password === confirmPassword) || 'Password must match']"
                  outlined
                >
                  <v-icon
                    slot="append"
                    color="primary"
                    @click="valueCPW = !valueCPW"
                  >
                    {{ valueCPW ? 'mdi-eye' : 'mdi-eye-off' }}
                  </v-icon>
                </v-text-field>
                <v-btn
                  color="primary"
                  block
                  large
                  class="mr-4"
                  submit
                  elevation="0"
                  :disabled="disableBtn()"
                  @click="submit"
                >
                  Activate your account
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import snackbarPlugin from '@/plugins/snackbar'
import UserService from '@/services/user.service.js'
import AuthService from '@/services/auth.service'
import User from '@/models/user'

import { zxcvbnAsync, zxcvbnOptions, debounce } from '@zxcvbn-ts/core'
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import matcherPwnedFactory from '@zxcvbn-ts/matcher-pwned'

const matcherPwned = matcherPwnedFactory(fetch, zxcvbnOptions)
zxcvbnOptions.addMatcher('pwned', matcherPwned)

const options = {
  // recommended
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary
  },
  // recommended
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  // recommended
  useLevenshteinDistance: true,
  // optional
  translations: zxcvbnEnPackage.translations
}

zxcvbnOptions.setOptions(options)

export default {
  name: 'Register',
  metaInfo: {
    title: 'Register'
  },
  components: {
  },
  data: () => ({
    provider: null,
    registrationToken: null,
    user: new User('', ''),

    result: null,
    debounce,

    phone: null,
    password: '',
    confirmPassword: null,
    valid: true,
    valuePW: String,
    valueCPW: String,
    passwordRules: [
      v => !!v || 'Password is required',
      password => (password && password.length >= 10) || 'Your password must have at least 10 characters',
      password => /[A-Za-z0-9]/.test(password) || 'Password must be valid'
    ],
    phoneRules: [
      v => !!v || 'Phone is required',
      v => /^[/+]?[(]?[0-9]{3}[)]?[-/s/.]?[0-9]{3}[-/s/.]?[0-9]{4,6}$/im.test(v) || 'Phone must be valid'
    ],

    internal: true,
    azure: false,
    google: false
  }),
  watch: {
    password() {
      this.debounce()
    }
  },
  beforeMount() {
    this.debounce = debounce(this.useZxcvbn, 200)
    this.checkToken()
  },
  methods: {
    disableBtn() {
      if (this.password && this.result) {
        return !(this.password.length >= 10 && this.result.score >= 2 && this.password === this.confirmPassword)
      } else {
        return true
      }
    },
    setResult(result) {
      this.result = result
    },
    async useZxcvbn() {
      if (this.password) {
        this.result = await zxcvbnAsync(this.password)
      } else {
        this.result = null
      }
    },
    async checkToken() {
      if (this.$route.params.token) {
        const res = await UserService.ComfirmUser(this.$route.params.token)
        if (res.status === 200 || res === 201) {
          this.registrationToken = res.data.registration_token
          this.provider = res.data.provider
          this.selectecProvider()
        } else {
          await this.$router.push('/login')
        }
      } else {
        await this.$router.push('/login')
      }
    },
    goToCorporateWebsite() {
      window.location.href = ('https://www.vumetric.com')
    },
    selectecProvider() {
      if (this.provider === 'azure') {
        this.internal = false
        this.azure = true
      }
      if (this.provider === 'google') {
        this.internal = false
        this.google = true
      }
      if (this.provider === 'null' || this.provider === null) {
        this.internal = true
        this.google = true
        this.azure = true
      }
    },
    async ssoConnection(provider) {
      const res = await AuthService.ssoRegister(provider, this.registrationToken)
      window.location.href = res.authUrl
    },
    async submit() {
      if (this.$refs.form.validate()) {
        await UserService.ActivateUser(
          {
            phone: this.phone,
            password: this.password,
            provider: this.provider
          },
          this.registrationToken
        ).then((resp) => {
          localStorage.user = JSON.stringify(resp.data)
          snackbarPlugin.showSuccess('Connected')
          UserService.getProfile().then((profile) => {
            this.$store.commit('auth/setProfile', profile)
            this.$router.push('/dashboard')
          })
        })
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>

<style lang="scss">
.backgroundImg {
  background-image: url('../../assets/images/background-hex.png');
}
</style>
